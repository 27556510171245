import { render, staticRenderFns } from "./ticket.vue?vue&type=template&id=2434c940&"
import script from "./ticket.vue?vue&type=script&lang=js&"
export * from "./ticket.vue?vue&type=script&lang=js&"
import style0 from "./ticket.vue?vue&type=style&index=0&id=2434c940&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports